<template>
    <div class="activeDis">
      <van-nav-bar title="详情">
        <template #left>
          <van-icon :size="24" @click="$router.go(-1)" color="#141212" name="arrow-left" slot="left" />
        </template>
      </van-nav-bar>
      <div class="bannerTitle">{{active.title}}</div>
      <div style="text-align:justify;text-justify:inter-ideograph;" class="bannerHome" v-html="active.content"></div>
    </div>
</template>

<script src="./homeBanner.js">

</script>

<style scoped src="./homeBanner.css">

</style>
