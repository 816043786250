
import { NavBar, Icon} from 'vant'
export default {
  components:{
    'van-icon':Icon,
    'van-nav-bar':NavBar,
  },
  data() {
    return{
      contentId:"",
      apis:{//接口列表
        info:'/content/findContent',//特色活动详情
      },
      active:{}
    }

  },
  mounted() {
    const me = this;
    me.contentId = me.$route.params.id;
    me.loadActive();
  },

  created(){
  },
  methods: {

    exit(){
      this.$router.go(-1)
    },
    loadActive(){//加载活动列表
      const me = this;
      me.$get({
        url: me.apis.info,
        params: {
          contentId:  me.contentId,
          token: me.$global.token
        }
      }).then(rsp => {
        me.active = rsp;
      });
    }
  }

}
